@import "./styles/colors.scss";

@import "../node_modules/bootstrap/scss/bootstrap.scss";

.card {
  display: inline-block;
}

@font-face {
  font-family: "Poppins";
  src: url(./assets/fonts/Poppins/Poppins-VariableFont_wght.woff2)
      format("woff2 supports variations"),
    url(./assets/fonts/Poppins/Poppins-VariableFont_wght.woff2)
      format("woff2-variations");
  font-weight: 100 1000;
  font-stretch: 25% 151%;
}

@font-face {
  font-family: "Inter";
  src: url(./assets/fonts/Inter/Inter.var.woff2)
      format("woff2 supports variations"),
    url(./assets/fonts/Inter/Inter.var.woff2) format("woff2-variations");
  font-weight: 100 1000;
  font-stretch: 25% 151%;
}

.logotype {
  font-family: "Inter", sans-serif;
  color: $purple;
  width: 15rem;
  font-size: 1.8rem;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.logotype-ads {
  color: #ffffff;
  font-weight: 400;
}

.dropdown-item.logout {
  color: $danger;
}

.nav-link {
  color: #ffffff;
}

// .nav-link.active {
//   // color: $purple;
// }

.search-active {
  padding: 1rem;
}

.filters .btn {
  margin: 1rem;
  margin-left: 0rem;
  // margin-right: 1rem;
}

.app-image {
  width: 3rem;
  height: 3rem;
  border-radius: 20%;
  margin: 2%;
}

.app-image-medium {
  width: 6rem;
  height: 6rem;
  border-radius: 20%;
  margin: 2%;
}

.spacer-right {
  margin-right: 1rem;
}

.spacer-left {
  margin-left: 1rem;
}

.spacer-bottom {
  margin-bottom: 0.5rem;
}

.spacer-top {
  margin-top: 1rem;
}

.btn-round {
  border-radius: 1000px;
}

.btn-primary {
  color: #ffffff;
}

.btn-primary:hover {
  color: #ffffff;
}

.btn-outline-primary:hover {
  color: #ffffff;
}

.btn-outline-warning:hover {
  color: #ffffff;
}

.btn-outline-success:hover {
  color: #ffffff;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.overlay-content {
  color: #ffffff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.disabled-graph {
  opacity: 40%;
}

.primary-text {
  color: $primary;
  font-weight: bold;
}

label {
  margin: 0.5rem;
  margin-left: 0rem;
  width: 100%;
}

body {
  background-color: map-get($tw_blueGray, 100);
}

.card-header {
  border-radius: 1rem 1rem 0rem 0rem !important;
}
.card {
  border-radius: 1rem !important;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px;
}
