.ad-container {
  background-color: #ffffff;
  width: 24rem;
  max-height: 6rem;
}

.ad-image {
  width: 6rem;
  height: 6rem;
  // margin: 2%;
}

.ad-text {
  width: 15rem;
}

.ad-text-source {
  font-size: 0.5rem;
}

a {
  color: #000;
  text-decoration: none;
}

.ad-progress-circle {
  height: 1rem;
  margin: 0.5rem 0rem;
}

.input-info-icon {
  vertical-align: text-top;
}
