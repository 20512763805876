$positionIndicatorSize: 8;

.tooltip-example {
  z-index: 0;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  background: linear-gradient(45deg, #6c5b7b, #c06c84, #f67280);
  font-size: 14px;
  color: white;
  width: 100%;
  height: 100%;
}
.tooltip-controls label {
  font-size: 14px;
  margin-right: 8px;
}
.position-indicator {
  width: $positionIndicatorSize px;
  height: $positionIndicatorSize px;
  border-radius: 50%;
  background: #35477d;
  position: absolute;
}
.crosshair {
  position: absolute;
  top: 0;
  left: 0;
}
.crosshair.horizontal {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #35477d;
}
.crosshair.vertical {
  height: 100%;
  width: 1px;
  border-left: 1px dashed #35477d;
}
.no-tooltip {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.z-index-bummer {
  position: absolute;
  right: 12%;
  bottom: 20%;
  max-width: 190px;
  z-index: 2000;
  background: rgba(255, 255, 255, 0.8);
  color: #35477d;
  border-radius: 8px;
  padding: 16px;
  line-height: 1.2em;
}
