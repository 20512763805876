@import "../../styles/colors.scss";

.navbar-link {
  color: #ffffff;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-link.active {
  border-radius: 0.5rem;
  background-color: map-get($tw_blueGray, 800);
  color: #ffffff;
  font-weight: bold;
}

.navbar-link:hover {
  color: #ffffff;
  font-weight: bold;
}

.navbar-dark .navbar-brand {
  color: map-get($tw_violet, 500);
}

.navbar-dark .navbar-brand:hover {
  color: map-get($tw_violet, 500);
}

.navbar {
  background-color: map-get($tw_blueGray, 700);
}

.navbar-heading {
  color: #eeeeee;
  font-size: 90%;
}
